<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" :back="$route.meta.back" :readonly="$authorised('con/registration/access', 'read')">

		<app-content-head-actions-item :disabled="item.status === $constants.registration.sale.status.refunded || (is.refunding !== true && is.refunding)" :loading="is.refunding === true" icon="refund" text="Refund" v-on:click="onRefundClick" />

	</app-content-head>

	<app-content-body v-if="!is.loading">

		<app-content-summary>

			<app-content-summary-item label="Ref." :value="item.reference" />
			<app-content-summary-item label="Type" :value="$constants.registration.sale.types[item.type]" />
			<app-content-summary-item label="Status" :tag="$constants.registration.sale.status[item.status]" :status="item.status" />
			<app-content-summary-item label="Date" :grow="true" :value="item.date.made | localDate('MM/DD/YYYY h:mma')" />
			<app-content-summary-item v-if="item.discount.code" label="Discount Code" :value="item.discount.code" />
			<app-content-summary-item v-if="item.cost.discount" label="Discount Amount" :value="item.cost.discount | formatMoney" />
			<app-content-summary-item label="Total" :value="item.cost.total | formatMoney" />

		</app-content-summary>

		<app-content-summary>

			<app-content-summary-item label="User" :value="item.user.name" />
			<app-content-summary-item label="Email" :grow="true" :is-email="true" :value="item.user.email" />
			<app-content-summary-item v-if="item.stripe.method" label="Payment method" :icon="'method-' + item.stripe.method" :value="'**** ' + item.stripe.preview" />

		</app-content-summary>
		
		<app-content-box v-for="badge in badges" :key="badge.id" :not-padded="true">

			<div class="sale-badge-head">

				<div class="sale-badge-head-icon"><app-icon icon="user" /></div>
				<div class="sale-badge-head-name">{{ badge.name }}</div>
				<div class="sale-badge-head-email"><a :href="'mailto:' + badge.email">{{ badge.email }}</a></div>
				<div class="sale-badge-head-badge">No. {{ badge.reference }}</div>
				<div class="sale-badge-head-reference">Ref. {{ badge.referenceRandom }}</div>

			</div>

			<div class="sale-badge-item" :data-status="item.status" v-for="item in badgeItems(badge.id)" :key="item.id">

				<div class="sale-badge-item-name"><div class="label" :data-status="item.status" v-if="item.status === 2">{{ $constants.registration.sale.status[item.status] }}</div>{{ item.name }}</div>
				<div class="sale-badge-item-price">{{ item.price | formatMoney }}</div>
				<div class="sale-badge-item-actions">
					<app-icon icon="refund" :disabled="(is.refunding && is.refunding !== item.id) || item.status === 2 || !item.refundable || $authorised('con/registration/access', 'read')" :loading="is.refunding === item.id" class="sale-badge-item-actions-item" v-tooltip="'Refund item'" v-on:click.native="onRefundClick(item.id)" />
				</div>

			</div>

		</app-content-box>
		
		<app-content-box :not-padded="true" v-if="otherItems.length">

			<div class="sale-badge-head">

				<div class="sale-badge-head-icon"><app-icon icon="receipt" /></div>
				<div class="sale-badge-head-name">Items</div>

			</div>

			<div class="sale-badge-item" :data-status="item.status" v-for="item in otherItems" :key="item.id">

				<div class="sale-badge-item-name"><div class="label" :data-status="item.status" v-if="item.status === 2">{{ $constants.registration.sale.status[item.status] }}</div>{{ item.name }}</div>
				<div class="sale-badge-item-price">{{ item.price | formatMoney }}</div>
				<div class="sale-badge-item-actions">
					<app-icon icon="refund" :disabled="(is.refunding && is.refunding !== item.id) || item.status === 2 || !item.refundable || $authorised('con/registration/access', 'read')" :loading="is.refunding === item.id" class="sale-badge-item-actions-item" v-tooltip="'Refund item'" v-on:click.native="onRefundClick(item.id)" />
				</div>

			</div>

		</app-content-box>

	</app-content-body>

	<com-refund v-if="is.refunding" :item="is.refunding" :sale="item" :name="refundName" :api="saveURL('refund')" v-on:refunded="onRefunded" v-on:close="is.refunding = false" />

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import Vue from 'vue'

import comRefund from './conventionregistrationsale/Refund'

export default {

	mixins: [mixForm],

	components: {
		comRefund
	},

	data: function() {

		return {
			is: {
				refunding: false
			},
			model: {
				id: false
			}
		}

	},

	validations: {
		model: {
		}
	},

	computed: {

		refundName: function() {

			if (this.is.refunding) {

				return (this.is.refunding === true) ? this.title : this.$_.findWhere(this.item.items, {
					id: this.is.refunding
				}).name

			} else {

				return false

			}

		},

		title: function() {

			return 'Sale #' + this.item.reference

		},

		otherItems: function() {

			return this.$_.filter(this.item.items, function(item) {

				return item.type === this.$constants.registration.item.type.other || item.badge === false

			}.bind(this))

		},

		badges: function() {

			var badges = []

			this.$_.each(this.item.items, function(item) {

				if (item.badge) {

					if(!this.$_.contains(this.$_.pluck(badges, 'id'), item.badge.id)) badges.push(item.badge)

				}

			}.bind(this))

			return badges

		}

	},

	methods: {

		badgeItems: function(badge) {

			return this.$_.filter(this.item.items, function(item) {

				return item.badge.id === badge

			})

		},

		onRefundClick: function(item) {

			this.is.refunding = item || true

		},

		onRefunded: function(json) {

			Vue.set(this, 'item', this.$util.copy(json.item))
			Vue.set(this, 'model', this.$util.copy(json.model))

		}

	}

}

</script>

<style scoped>

.label {
	color: #fff;
	height: 20px;
	font-size: 12px;
	line-height: 20px;
	padding: 0px 4px;
	border-radius: 4px;
}

.sale-summary-item-value >>> .icon {
	font-size: 20px;
	margin-right: 10px;
	color: #4082d3;
}

.sale-badge-head {
	display: flex;
	border-bottom: 1px solid #ddd;
	align-items: center;
}

.sale-badge-head-icon {
	width: 24px;
	height: 24px;
	background-color: #4082d3;
	color: #fff;
	font-size: 12px;
	text-align: center;
	line-height: 26px;
	border-radius: 50%;
	margin-right: 10px;
	margin-left: 10px;
}

.sale-badge-head-name {
	font-size: 16px;
	line-height: 40px;
	color: #343434;
	flex-grow: 1;
}

.sale-badge-head-email {
	font-size: 14px;
	color: #666;
	line-height: 40px;
	padding: 0px 10px;
	height: 40px;
	border-right: 1px solid #ddd;
}

.sale-badge-head-email a:hover {
	color: #4082d3;
}

.sale-badge-head-badge {
	font-size: 14px;
	padding: 0px 10px;
	display: block;
	color: #666;
}

.sale-badge-head-reference {
	font-size: 14px;
	padding: 0px 10px;
	border-left: 1px solid #ddd;
	height: 40px;
	line-height: 40px;
	display: block;
	color: #666;
}

.sale-badge-item {
	display: flex;
	line-height: 24px;
	padding: 10px 10px;
	border-bottom: 1px solid #eee;
}

.sale-badge-item[data-status="2"] {
	background-color: #fff3e7;
}

.sale-badge-item:last-child {
	border-bottom-width: 0px;
}

.sale-badge-item-name {
	font-size: 14px;
	flex-grow: 1;
	color: #343434;
	display: flex;
	align-items: center;
}

.sale-badge-item-name .label {
	margin-right: 10px;
}

.sale-badge-item-name .label[data-status="2"] {
	background-color: #ffbe7a;
}

.sale-badge-item-price {
	font-size: 14px;
	flex-shrink: 0;
	width: 200px;
	text-align: right;
}

.sale-badge-item-actions {
	margin-left: 20px;
	display: flex;
}

.sale-badge-item-actions-item {
	height: 24px;
	width: 24px;
	cursor: pointer;
	font-size: 12px;
	border-radius: 4px;
	line-height: 24px;
	background-color: #4082d3;
	text-align: center;
	color: #fff;
}

.sale-badge-item-actions-item:hover {
	background-color: #236dc9;
}

</style>